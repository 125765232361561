<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <div id="loading-bg">
      <div class="loading">
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>
    <cookie-law
      v-if="canShowConcent"
      theme="dark-lime"
      :button-text="$t('I accept')"
    >
      <div slot="message">
        {{ $t('We utilize cookies on our website to provide you with the most relevant user experience. For more details, we encourage you to review our ') }}
        <b-link
          class="text-white text-underline"
          style="text-decoration: underline"
          :to="{
            path: '/privacy-policy',
          }"
        >{{ $t('privacy policy') }}.</b-link>
      </div>
    </cookie-law>
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
/* eslint-disable eqeqeq */

import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { io } from 'socket.io-client'
import { BLink } from 'bootstrap-vue'
import CookieLaw from 'vue-cookie-law'
import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import jwtDefaultConfig from './@core/auth/jwt/jwtDefaultConfig'
import ScrollToTop from './@core/components/scroll-to-top/ScrollToTop.vue'
import eventBus from './libs/event-bus'
import { showSuccessNotification } from './@core/comp-functions/ui/app'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    BLink,
    LayoutFull,
    CookieLaw,
    ScrollToTop,
  },
  data() {
    return {
      socket: null,
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    canShowConcent() {
      return (window.location.pathname === '/' && this.$route.path === '/') || (window.location.pathname === '/shop' && this.$route.path === '/shop')
    },
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      if (this.$route.meta.layout === 'horizontal') return 'layout-horizontal'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    currentBus() {
      return eventBus
    },
  },
  beforeUnmount() {
    if (this.socket) {
      console.log('Disconnected from Socket')
      this.socket.disconnect()
      eventBus.$off('chat-message', this.sendMessage)
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.meta.pageTitle) {
          document.title = `${this.$route.meta.pageTitle} - MOM App`
        } else {
          document.title = 'MOM App'
        }
      },
    },
    '$route.path': {
      handler() {
        const body = document.getElementsByTagName('body')[0]
        if (body) {
          body.classList.remove('shop-login')
          body.classList.remove('provider-login')
          body.classList.remove('user-login')
          if (this.$route.path.startsWith('/shop')) {
            body.classList.add('shop-login')
          } else if (this.$route.path.startsWith('/provider')) {
            body.classList.add('provider-login')
          } else if (this.$route.path.startsWith('/user')) {
            body.classList.add('user-login')
          }
          this.setTawkView()
        }
      },
    },
  },
  destroyed() {
    if (this.socket) {
      console.log('Disconnected from Socket')
      this.socket.disconnect()
      eventBus.$off('chat-message', this.sendMessage)
      eventBus.$off('refresh-on-going-services-socket', this.refreshOnGoing)
      eventBus.$off('store-order-checkout', this.sendMessage)
      eventBus.$off('provider-order-checkout', this.providerServiceCheckout)
      eventBus.$off('store-sync-app', this.storeSyncApp)
      eventBus.$off('user-sync-app', this.userSyncApp)
      eventBus.$off('provider-sync-app', this.providerSyncApp)
    }
  },
  mounted() {
    this.socket = io(jwtDefaultConfig.socketIO)
    console.log('Connected to Socket')
    eventBus.$on('chat-message', this.sendMessage)
    eventBus.$on('store-sync-app', this.storeSyncApp)
    eventBus.$on('user-sync-app', this.userSyncApp)
    eventBus.$on('provider-sync-app', this.providerSyncApp)
    eventBus.$on('store-order-checkout', this.storeOrderCheckout)
    eventBus.$on('provider-order-checkout', this.providerServiceCheckout)
    eventBus.$on('refresh-on-going-services-socket', this.refreshOnGoing)
    window.socketApp = this
    setTimeout(() => {
      this.setTawkView()
    }, 250)
    this.socket.on('chat-message', data => {
      if (data.extraParamsChat) {
        if (data.action === 'reload-contacts') {
          eventBus.$emit('reload-contact-user', data)
        } else if (data.action === 'new-message-contacts') {
          eventBus.$emit('reload-user-new-message', data)
        }
      } else {
        eventBus.$emit('user-messaged', data)
      }
    })
    this.socket.on('user-sync-app', data => {
      const userInfo = window.SuperApp.getters.userInfo()
      if (userInfo && data && data.userId == userInfo.id && userInfo.user_type === 'NORMAL') {
        if (data.rescheduled) {
          if (window.SuperApp.helper.isNotificationGranted()) {
            const notification = new Notification('Order updated', {
              icon: window.socketApp.appLogoImage,
              body: `Your request for booking have been rescheduled from Shop: ${data.shopName}.\nBooking No: ${data.invoiceId}`,
            })
            notification.onclick = () => {
              this.$router.push({
                path: '/user/order',
              })
            }
          } else {
            window.SuperApp.helper.requestPermission()
          }
        } else if (data.bookingCreated) {
          if (window.SuperApp.helper.isNotificationGranted()) {
            const notification = new Notification('Booking Created', {
              icon: window.socketApp.appLogoImage,
              body: `Your request for booking had been created from Shop: ${data.shopName}.\nBooking No: ${data.invoiceId}`,
            })
            notification.onclick = () => {
              this.$router.push({
                path: '/user/order',
              })
            }
          } else {
            window.SuperApp.helper.requestPermission()
          }
        } else if (data.serviceBookingCreated) {
          if (window.SuperApp.helper.isNotificationGranted()) {
            const notification = new Notification('Service Booked', {
              icon: window.socketApp.appLogoImage,
              body: `Your request for service booking had been created from Provider: ${data.providerName}.\nService No: ${data.invoiceId}`,
            })
            notification.onclick = () => {
              this.$router.push({
                path: '/user/services',
              })
            }
          } else {
            window.SuperApp.helper.requestPermission()
          }
        } else if (data.serviceRescheduled) {
          if (window.SuperApp.helper.isNotificationGranted()) {
            const notification = new Notification('Service Booked', {
              icon: window.socketApp.appLogoImage,
              body: `Your request for service booking had been rescheduled from Provider: ${data.providerName}.\nService No: ${data.invoiceId}`,
            })
            notification.onclick = () => {
              this.$router.push({
                path: '/user/services',
              })
            }
          } else {
            window.SuperApp.helper.requestPermission()
          }
        }
      }
    })
    this.socket.on('refresh-on-going-services', data => {
      const userInfo = window.SuperApp.getters.userInfo()
      if (userInfo && data && data.userId == userInfo.id && userInfo.user_type === 'NORMAL') {
        eventBus.$emit('refresh-on-going-services')
      }
      if (window.SuperApp.getters.isUser()) {
        if (data.service === 'SERVICE' || data.service === 'ORDER') {
          if (window.SuperApp.helper.isNotificationGranted()) {
            const notification = new Notification('Request updated', {
              icon: window.socketApp.appLogoImage,
              body: 'Changes had been made, please check it',
            })
            eventBus.$emit('order-updated', data)
            notification.onclick = () => {
              if (data.service === 'SERVICE') {
                this.$router.push({
                  path: `/user/service/request/${data.serviceId}`,
                })
              } else if (data.service === 'ORDER') {
                this.$router.push({
                  path: `/user/store/order/${data.orderId}`,
                })
              }
            }
          } else {
            window.SuperApp.helper.requestPermission()
          }
        }
      }
      eventBus.$emit('order-updated', data)
    })
    this.socket.on('store-sync-app', data => {
      const userInfo = window.SuperApp.getters.userInfo()
      if (userInfo && window.SuperApp.getters.isStore() && data && userInfo.id == data.storeId) {
        if (data.refreshDashboard) {
          eventBus.$emit('refresh-store-accept-bars')
          eventBus.$emit('refresh-store-accept-grid')
        }
      }
    })
    this.socket.on('provider-sync-app', data => {
      const userInfo = window.SuperApp.getters.userInfo()
      if (userInfo && window.SuperApp.getters.isProvider() && !window.SuperApp.getters.isAgent() && data && userInfo.id == data.providerId) {
        if (data.refreshDashboard) {
          eventBus.$emit('refresh-provider-accept-bars')
          eventBus.$emit('refresh-provider-accept-grid')
          if (data.showNotification) {
            if (window.SuperApp.helper.isNotificationGranted()) {
              const notification = new Notification('Request updated', {
                icon: window.socketApp.appLogoImage,
                body: data.showNotification,
              })
              notification.onclick = () => {
                this.$router.push({
                  path: `/provider/service/agent/view/${data.requestId}`,
                })
              }
            } else {
              window.SuperApp.helper.requestPermission()
            }
          }
        }
      }
      if (userInfo && window.SuperApp.getters.isProvider() && window.SuperApp.getters.isAgent() && data && userInfo.id == data.agentId) {
        if (data.showIncomingRequest) {
          const isAnyOpen = document.getElementById('incomingAgentCardsModalShared') || document.getElementById('incomingAgentCardsModal')
          if (!isAnyOpen) {
            if (window.SuperApp.helper.isNotificationGranted()) {
              const notification = new Notification('New order assigned', {
                icon: window.socketApp.appLogoImage,
                body: 'Check the assigned order',
              })
              notification.onclick = () => {
                eventBus.$emit('show-incoming-request')
              }
            } else {
              window.SuperApp.helper.requestPermission()
            }
          }
        }
      }
    })
    this.socket.on('store-order-checkout', data => {
      console.log(data, 'Notification store-order-checkout');
      const userInfo = window.SuperApp.getters.userInfo()
      if (data && userInfo && window.SuperApp.getters.isStore()) {
        if (data.storeId === userInfo.id && data.service === 'ORDER') {
          if (window.SuperApp.helper.isNotificationGranted()) {
            const notification = new Notification('New order received', {
              icon: window.socketApp.appLogoImage,
              body: `You have received a new order from ${data.userInfo}.\nInvoice No: ${data.invoiceId}`,
            })
            notification.onclick = () => {
              this.$router.push({
                path: '/shop/dashboard',
              })
            }
          } else {
            window.SuperApp.helper.requestPermission()
          }
          window.SuperApp.actions.newOrder()
          showSuccessNotification(this, `You have received a new order from ${data.userInfo}.\nInvoice No: ${data.invoiceId}`)
          eventBus.$emit('refresh-store-accept-bars')
          if (window.location.pathname !== '/shop/dashboard') {
            eventBus.$emit('set-new-order-store-notification')
          }
        }
      }
    })
    this.socket.on('provider-order-checkout', data => {
      const userInfo = window.SuperApp.getters.userInfo()
      if (data && userInfo && window.SuperApp.getters.isProvider() && !window.SuperApp.getters.isAgent()) {
        if (data.providerId === userInfo.id && data.service === 'SERVICE') {
          if (window.SuperApp.helper.isNotificationGranted()) {
            const notification = new Notification('New service booked', {
              icon: window.socketApp.appLogoImage,
              body: `New service has been booked by ${data.userInfo}.\nBooking No: ${data.bookingId}`,
            })
            notification.onclick = () => {
              this.$router.push({
                path: '/shop/requests',
              })
            }
          } else {
            window.SuperApp.helper.requestPermission()
          }
          window.SuperApp.actions.newOrder()
          showSuccessNotification(this, `New service has been booked by ${data.userInfo}.\nBooking No: ${data.bookingId}`)
          eventBus.$emit('refresh-provider-accept-bars')
          if (window.location.pathname !== '/provider/requests') {
            eventBus.$emit('set-new-order-provider-notification')
          }
        }
      }
    })
    window.SuperApp.helper.changeTranslation(this, window.SuperApp.getters.userLang())
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  methods: {
    setTawkView() {
      if (window.Tawk_API && window.Tawk_API.hideWidget && window.Tawk_API.showWidget) {
        window.Tawk_API.hideWidget()
      }
    },
    sendMessage(payload) {
      if (payload.extraParamsChat) {
        this.socket.emit('chat-message', payload)
      } else {
        this.socket.emit('chat-message', payload)
      }
    },
    refreshOnGoing(payload) {
      this.socket.emit('refresh-on-going-services', payload)
    },
    storeSyncApp(payload) {
      this.socket.emit('store-sync-app', payload)
    },
    userSyncApp(payload) {
      this.socket.emit('user-sync-app', payload)
    },
    providerSyncApp(payload) {
      this.socket.emit('provider-sync-app', payload)
    },
    storeOrderCheckout(payload) {
      this.socket.emit('store-order-checkout', payload)
    },
    providerServiceCheckout(payload) {
      this.socket.emit('provider-order-checkout', payload)
    },
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 1500,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    store.commit('app/UPDATE_NO_IMAGE_AVAILABLE', 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkkDW-YVGc7gYEk8ekvu8v9R8zPZRzd-XOfg&usqp=CAU')
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
      window.SuperApp.helper.setMobileView()
      window.SuperApp.actions.setLandingPageHeight()
      if (window.Tawk_API) {
        window.Tawk_API.hideWidget()
      }
    })
    const { appLogoImage } = $themeConfig.app
    return {
      skinClasses,
      appLogoImage,
    }
  },
}
</script>

<style>
.Cookie--dark-lime .Cookie__button {
  background: #28c76f;
  font-size: 16px;
}

body .input-tel.has-value .input-tel__label {
  display: none !important;
}

body .input-tel.has-hint .input-tel__input,
body .input-tel.has-value .input-tel__input {
  padding-top: 0 !important;
}

body .fit-image-contains {
  object-fit: contain !important;
}

.Cookie__content {
  font-size: 17px;
  font-family: 'Roboto Slab', serif;
  font-weight: normal;
  font-style: normal;
}

.Cookie__buttons {
  margin: 0 !important;
}

.custom-icons {
  border: 1px solid #ddd;
  padding: 2px 5px;
  border-radius: 4px;
}

body .item-names {
  font-size: 13px;
}

.custom-icons img {
  margin-right: 5px;
  height: 20px;
}

.no-padding-layout .horizontal-layout.navbar-floating .header-navbar-shadow {
  display: none;
}

.payment-form-done-bg {
  background: rgba(115, 103, 240, 0.12);
  padding-top: 10px;
}

.mz-figure a[target='_blank'] {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
}

.opacity-0 {
  opacity: 0 !important;
}

[dir='rtl'] .switch-rtl-info {
  direction: rtl;
}

body .row-inner-tables.text-center .custom-control-inline {
  margin-right: 0;
}

.img-fit-app {
  object-fit: fill !important;
}

.set-extra-images,
.fix-footer-product-card {
  position: relative;
}

.fix-footer-product-card {
  padding-bottom: 38px;
}

.footer-area-product {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.set-extra-images .food-type-image,
.set-extra-images .best-seller-image,
.set-extra-images .recommended-image,
.set-extra-images .out-of-stock-image {
  width: 30px !important;
  object-fit: contain !important;
  height: 30px !important;
  z-index: 9;
}

.set-extra-images .extra-image-ui {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  justify-content: space-between;
}

.set-extra-images .out-of-stock-image {
  left: 50%;
  position: absolute;
  top: 50%;
  width: 80px !important;
  height: 80px !important;
  transform: translate(-50%, -50%);
}

.vue-phone-number-input.is-invalid input,
body .ui.search.selection.dropdown.is-invalid {
  border-color: #ea5455 !important;
}

.overflow-effect-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  word-break: break-word;
  padding-bottom: 0 !important;
}

.set-extra-images .best-seller-image {
  left: unset;
  right: 0;
  top: 5px;
}

.set-extra-images .recommended-image {
  right: 30px;
  left: unset;
  top: 5px;
}

body .iti-flag {
  background-image: url('~@/assets/images/app-images/flags.png') !important;
}

figure {
  margin: 0 !important;
  width: 100%;
  height: 100%;
}

body #app .mz-ready div a,
body #app .mz-ready span a {
  opacity: 0 !important;
  pointer-events: none;
}

/* figure:hover img {
  opacity: 0;
} */

.vgt-responsive th.vgt-left-align {
  text-align: center !important;
}

.no-padding-layout .app-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 60px !important;
}

.search-location-size {
  width: 45vw;
}

.custom-slider::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.leaflet-routing-container.leaflet-bar.leaflet-control {
  width: 200px;
}

#mapboxLeaflet {
  z-index: 1;
}

.custom-slider::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.custom-slider::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d;
}

.user-store-list section .ecommerce-card .item-img a,
.user-service-list section .ecommerce-card .item-img a,
.user-service-details section .ecommerce-card .item-img a,
.user-provider-store-details section .ecommerce-card .item-img a {
  width: 100%;
}

body {
  background: #fff !important;
  font-family: 'Roboto Slab', serif !important;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.header-navbar,
.navigation {
  font-family: 'Roboto Slab', serif !important;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.text-black {
  color: #000;
}

.form-extra-label {
  font-size: 14px;
}

.form-extra-label span {
  font-size: 12px;
}

.wrap-top-search-bar {
  background: #fff;
  box-shadow: rgb(28 28 28 / 12%) 0px 2px 8px;
  border-radius: 44px;
  padding: 5px 15px;
  width: 30vw;
  margin-right: 20px;
}

[dir] .modal .carousel-control-prev-icon,
[dir] .modal .carousel-control-next-icon {
  background-color: #7367f0;
  padding: 20px;
  border-radius: 50%;
  background-size: inherit;
}

[dir] .modal .carousel-control-prev,
[dir] .modal .carousel-control-next {
  opacity: 0.7;
}

body .modal .carousel-item {
  height: 85vh;
}

body .modal .carousel-item img {
  height: 85vh;
  object-fit: contain;
}

body #slider-modal .modal-dialog {
  max-width: 80%;
  max-height: 90%;
}

.user-landing-page #nav-collapse .nav-link {
  color: #fff !important;
}

body #app .pb-0-important {
  padding-bottom: 0 !important;
}

.swiper-pagination-bullet-active {
  background: red !important;
}

.object-fit-knowledge img {
  height: 350px;
  object-fit: contain;
}

.nav-image-sidebar {
  width: 20px;
  height: 20px;
  margin-right: 1.1rem;
}

.user-service-checkout .fc .fc-toolbar-chunk .fc-button-primary.fc-timeGridDay-button {
  background-color: rgba(115, 103, 240, 0.2) !important;
  border-color: #7367f0 !important;
  color: #7367f0 !important;
  border-radius: 4px !important;
  padding: 0.55rem 1.5rem !important;
  text-transform: capitalize !important;
}

body .custom-design {
  position: relative;
  padding: 10px 20px;
  border-radius: 7px;
  border: 1px solid #FFF;
  text-transform: uppercase;
  background: unset;
  color: #FFF !important;
  overflow: hidden;
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  display: inline-block;
}

body .custom-design:hover {
  background: rgb(61, 106, 255);
  color: #fff !important;
  box-shadow: 0 0 30px 5px rgba(0, 142, 236, 0.815);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

body .custom-design:hover::before {
  -webkit-animation: sh02 0.5s 0s linear;
  -moz-animation: sh02 0.5s 0s linear;
  animation: sh02 0.5s 0s linear;
}

body .custom-design::before {
  content: '';
  display: block;
  width: 0px;
  height: 86%;
  position: absolute;
  top: 7%;
  left: 0%;
  opacity: 0;
  background: #fff;
  box-shadow: 0 0 50px 30px #fff;
  -webkit-transform: skewX(-20deg);
  -moz-transform: skewX(-20deg);
  -ms-transform: skewX(-20deg);
  -o-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

@keyframes sh02 {
  from {
    opacity: 0;
    left: 0%;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
    left: 100%;
  }
}

body .custom-design:active {
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
}

.user-service-checkout .fc-timeGridDay-view tr.fc-scrollgrid-section.fc-scrollgrid-section-body:first-child,
.horizontal-layout .hamburger-menu {
  display: none;
}

.app-autocomplete-loading {
  text-align: center;
  padding: 10px;
}
.font-8 {
  font-size: 8px;
}
.font-9 {
  font-size: 9px;
}
.font-10 {
  font-size: 10px;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}
.font-12-imp {
  font-size: 12px !important;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-15 {
  font-size: 15px;
}
.font-16 {
  font-size: 16px;
}
.font-17 {
  font-size: 17px;
}
body .font-18 {
  font-size: 18px;
}
.font-19 {
  font-size: 19px;
}
.font-20 {
  font-size: 20px;
}
body .font-35 {
  font-size: 35px;
}
.no-style-ul {
  padding: 0;
}
.no-style-ul li {
  list-style-type: none;
}
.landing-page-section .color-red {
  color: #000;
}
.enable-transition {
  transition: all 0.3s ease-in-out, background 0s, color 0s, border-color 0s;
}
.enable-transition:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
}
.nav-tabs-center .nav-pills {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
body div div.card.card-wallet-info {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}
.checkout-forms.vue-form-wizard .wizard-navigation .wizard-nav li:not(:first-child) a::before {
  content: unset !important;
}
#select-user-address .modal-body,
#select-user-address-with-close .modal-body {
  max-height: 75vh;
  overflow-y: auto;
}

.sidebar-detached.store-side-bar {
  /* position: fixed; */
}

.vgt-wrap .vgt-responsive {
  overflow: visible;
}

.horizontal-menu .header-navbar.navbar-brand-center .navbar-header {
  position: static;
  padding-left: 25px !important;
}

.mb-05 {
  margin-bottom: 0.5rem;
}

.mr-05 {
  margin-right: 0.5rem;
}

.after-underline-100 {
  position: relative;
}

.after-underline-100::after {
  position: absolute;
  content: '';
  display: block;
  border-bottom: 2px solid #000;
  top: 0;
  bottom: 0;
  width: 100%;
}

body .box-shadow-card,
body .card .card.box-shadow-card {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}

.text-underline {
  text-decoration: underline;
  cursor: pointer;
}
.small {
  font-size: 75%;
}

.relative {
  position: relative;
}

.ecommerce-card .item-img img {
  display: block;
  width: 100%;
  object-fit: cover;
  height: 300px;
}

.store-ui .ecommerce-card .item-img img,
.user-e-commerce-chcekout .ecommerce-card .item-img img {
  object-fit: cover;
}

.disable-effect,
.disable-effect-stock {
  opacity: 0.5;
  pointer-events: none;
}

body .leaflet-marker-icon.leaflet-interactive,
body .leaflet-image-layer.leaflet-interactive,
body .leaflet-pane > svg path.leaflet-interactive {
  pointer-events: none !important;
}

.disable-effect-stock {
  opacity: 0.8;
}

.disable-effect-no-pointer {
  opacity: 0.5;
}

.out-of-stock-effect {
  position: absolute;
  z-index: 99999;
  font-size: 25px;
  font-weight: bold;
  background: #fff;
  padding: 10px;
  border-radius: 20px;
  bottom: 0;
}

.pac-container {
  z-index: 99999;
}

body .customer-landing-page-header.navbar-absolute {
  position: fixed !important;
  background: #fff !important;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1030;
  box-shadow: 0px 1px 15px 1px rgb(81 77 92 / 8%);
}

.customer-landing-page-header img {
  width: 50% !important;
}

.no-margin-radio .custom-control-inline {
  margin-top: 0;
}

body .vs__dropdown-toggle {
  border: 1px solid #d8d6de;
}

.customer-landing-page-header .navbar-toggler {
  border-color: #000;
}

.landing-page-wrapper .content-info {
  padding-top: 9rem;
  white-space: break-spaces;
}

.white-space-initial {
  white-space: initial !important;
}

.landing-page-wrapper .content-info.no-white-space {
  white-space: unset;
}

.backdrop-effect {
  backdrop-filter: blur(5px);
  padding-top: 10px;
  padding-bottom: 10px;
}

.theme-bg-color {
  background-color: #7367f0;
}

.img-transition {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.img-transition:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.theme-color {
  color: #7367f0;
}

body .custom-radio-right {
  padding-left: 5px !important;
  padding-right: 1.8rem !important;
}

body .no-radio {
  padding-right: 5px !important;
}

body .no-radio .custom-control-label::before,
body .no-radio .custom-control-label::after {
  display: none;
}

body .custom-radio-right .custom-control-input,
body .custom-radio-right .custom-control-label::before,
body .custom-radio-right .custom-control-label::after {
  left: unset !important;
  right: 2px !important;
}

.no-store-found-image {
  width: 300px;
  margin: 0 auto;
  display: block;
  margin-top: 20px;
}

body .auto-flow-scroll {
  max-height: 300px;
  overflow: auto !important;
}

/* ::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: rgb(176, 169, 169);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgb(176, 169, 169);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(176, 169, 169);
} */

.clamp-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.modal .left-section-login-page,
.modal .brand-logo,
#loginModal .modal-footer {
  display: none !important;
}

body .white-space-break {
  white-space: break-spaces;
}

#loginModal .auth-wrapper.auth-v2,
#loginModal .auth-wrapper.auth-v2 .auth-inner {
  height: auto !important;
  min-height: auto !important;
}

/* hide the cd-editor toolbars */
.ck.ck-editor__top.ck-reset_all {
  display: none !important;
}

.leaflet-top.leaflet-right h3 {
  margin: 0;
}

.show-toolbar-ck .ck.ck-editor__top.ck-reset_all {
  display: block !important;
}

body .modal .right-section-login-page {
  width: 100% !important;
  max-width: 100% !important;
  flex: 0 0 100%;
  padding: 1rem !important;
}

.pt-10 {
  padding-top: 7rem;
}

body .modal .base-image-input {
  width: auto;
}

body figure .vue-zoomer {
  width: 100%;
  height: 100%;
}

body .custom-scroll-height .modal .modal-body {
  max-height: 80vh;
  overflow-y: auto;
}

body .widget-visible iframe {
  zoom: 1.3;
}

.container.face-book-login-button {
  max-width: unset;
  width: unset;
  display: inline-block;
  margin: 0;
}

.container.face-book-login-button button {
  min-width: unset;
}

.container.face-book-login-button button {
  background-image: url('~@/assets/images/app-icons/icons8-facebook.svg');
  height: 35px;
  width: 35px;
  background-repeat: no-repeat;
  padding: 0;
  background-size: contain;
}

.container.face-book-login-button button .spinner,
.container.face-book-login-button button img {
  display: none;
}

body {
  zoom: 0.8;
}

body .reset-zoom {
  zoom: 1.2;
}

body .ql-video {
  width: 100%;
  height: 500px;
}

body .modal-backdrop {
  width: 100%;
  height: 100%;
}
.price_card{
  border: 1px solid transparent;
  border-radius: 1rem;
  padding-bottom: 0.5rem;
}
.price_card:hover{
  background-color: rgba(0,0,0,0.03);
  border: 1px solid rgba(255,255,255,0.3);
}
@media only screen and (max-width: 575.98px) {
  body .header-navbar.floating-nav {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  body .wrapper-responsive-address {
    display: block !important;
  }

  body .header-navbar.floating-nav {
    width: calc(100vw - (100vw - 100%) - calc(1rem)) !important;
  }
}

@media only screen and (max-width: 767px) {
  body .w-mobile-100 {
    width: 100% !important;
  }

  body .ql-video {
    height: 300px;
  }

  body {
    zoom: 1;
  }

  body .cart-item-qty {
    width: 90px;
  }

  body .cart-item-qty input {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  body .mobile-col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .btn-mobile-adjust .grid-main-action-button button {
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  body #app .wrap-top-search-bar {
    display: none !important;
  }

  .user-store-list .ecommerce-card .item-img img,
  .user-service-list .ecommerce-card .item-img img {
    height: 190px;
  }

  .user-service-details .ecommerce-card .item-img img {
    height: 100%;
  }

  body.user-login #app .wrap-top-search-bar {
    display: flex !important;
    width: 94%;
    margin: 0 auto;
    margin-bottom: 5px;
  }

  /* body.user-login #app .wrap-top-search-bar {
    display: flex !important;
    position: absolute;
    width: 90%;
    left: 15px;
    right: 15px;
    top: 60px;
    margin: 0;
  }

  body.user-login .horizontal-layout .app-content {
    padding: calc(7.45rem + calc(2rem - 0.8rem)) calc(2rem - 0.8rem) 0 calc(2rem - 0.8rem) !important;
  }

  body.user-login .app-content {
    padding: calc(5rem - 0.8rem + 4.45rem + 1.3rem) calc(2rem - 0.8rem) 0 calc(2rem - 0.8rem) !important;
  }

  body.user-login .horizontal-layout.mobile-view-class.navbar-floating:not(.blank-page) .app-content {
    padding-top: 115px !important;
  } */

  .leaflet-top.leaflet-right table,
  .leaflet-top.leaflet-right h2 {
    display: none !important;
  }

  /* body .leaflet-routing-container.leaflet-bar.leaflet-control {
    width: 150px;
  }

  body .leaflet-routing-alt,
  body .leaflet-routing-geocoders,
  body .leaflet-routing-error {
    max-height: 120px;
    overflow-y: auto;
  } */

  body .leaflet-routing-collapse-btn {
    color: #000;
  }

  body .mobile-p-0 {
    padding: 0 !important;
  }

  .set-extra-images .out-of-stock-image {
    width: 40px !important;
  }

  .out-of-stock-effect {
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    padding: 5px;
    font-size: 12px;
  }

  .mobile-static-position {
    position: static !important;
  }

  .user-login #app .mobile-static-position {
    margin: 0 !important;
  }

  .user-login #app .navbar-floating .navbar-container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .modal-open .modal {
    padding: 0 !important;
  }

  .shop-login .header-navbar .navbar-nav,
  .provider-login .header-navbar .navbar-nav {
    margin: 0;
    justify-content: flex-end;
  }

  .shop-login .header-navbar .navbar-nav.nav-wrapper-area,
  .provider-login .header-navbar .navbar-nav.nav-wrapper-area {
    width: 100%;
  }

  .user-login .header-navbar .navbar-nav .online-wrapper,
  .shop-login .header-navbar .navbar-nav .online-wrapper,
  .provider-login .header-navbar .navbar-nav .online-wrapper {
    justify-content: flex-end !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 26%;
  }

  .user-login .header-navbar .navbar-nav .online-wrapper {
    top: 40%;
  }

  .user-login .header-navbar .navbar-nav .nav-item.b-nav-dropdown.dropdown-cart {
    margin: 0 !important;
  }

  .user-login .header-navbar .navbar-nav .online-wrapper .form-extra-label,
  .shop-login .header-navbar .navbar-nav .online-wrapper .form-extra-label,
  .provider-login .header-navbar .navbar-nav .online-wrapper .form-extra-label {
    position: absolute;
    top: 100%;
    left: 0;
  }

  .shop-login .header-navbar .navbar-nav .page-name-title,
  .provider-login .header-navbar .navbar-nav .page-name-title {
    top: 5px;
    left: 15px;
  }

  .set-extra-images .food-type-image,
  .set-extra-images .best-seller-image,
  .set-extra-images .recommended-image {
    height: 20px !important;
  }

  [dir] body .grid-main-action-button img,
  body .grid-main-action-button img {
    margin-right: 2px !important;
  }

  .custom-search.d-flex.justify-content-end.grid-main-action-button {
    width: 55%;
  }

  .vgt-wrap .vgt-responsive {
    overflow: auto;
    overflow-x: auto;
  }

  body .vue-good-datatable-view .grid-main-action-button {
    display: block !important;
    text-align: right;
    margin-bottom: 10px;
  }

  body .custom-search fieldset label,
  .page-name-title {
    display: none;
  }

  .provider-requests .wrap-request-items,
  .provider-agent-requests .wrap-request-items {
    width: 100% !important;
  }

  .provider-requests .wrap-request-items button {
    padding: 10px !important;
  }

  .d-none-mobile {
    display: none !important;
  }

  body #app .ml-mobile-0 {
    margin-left: 0 !important;
  }

  body #app .mr-mobile-0 {
    margin-right: 0 !important;
  }

  body .mobile-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .footer-widget {
    margin-top: 20px;
  }

  .footer-widget h5 {
    margin-bottom: 5px !important;
  }

  .wrap-sidebar-filters.card {
    max-height: 93%;
    overflow-y: auto;
  }

  .modal .modal-dialog {
    max-width: 100% !important;
  }

  .show-tabs-list-mobile .nav .nav-item a span {
    display: block !important;
  }

  body .wrap-admin-orders .data-wrapper {
    width: 90% !important;
  }

  .provider-my-services .provider-services .wrap-admin-orders .data-wrapper {
    width: 100% !important;
  }

  body #app .pr-col-mobile {
    padding-left: 1rem !important;
  }
}

@media only screen and (max-width: 992px) {
  .sidebar-detached.store-side-bar {
    position: static;
  }

  .search-location-size {
    width: auto;
  }
}
</style>

<style lang="scss">
.vue-good-datatable-view {
  .header-name-columns {
    display: none;
  }

  @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    .header-name-columns {
      display: block;
      min-width: 120px;
      width: 120px;
      max-width: 120px;
    }

    .card-body {
      padding: 0;
    }

    .row-inner-tables {
      display: flex;
      justify-content: space-between;
    }

    .vgt-table.bordered td {
      padding: 5px !important;
    }

    .vgt-table.bordered td:first-child {
      background-color: #ebe9f1;
      color: #000;
      border: 0;
    }

    .row-inner-tables span {
      word-break: break-all;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
    }

    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
    }
  }
}

@media only screen and (min-width: 768px) {
  html .content {
    min-height: calc(105% - 3.35rem) !important;
  }

  body .sidenav-overlay {
    height: 125vh;
  }

  body .auth-wrapper.auth-v2 .auth-inner {
    height: calc(var(--vh, 1vh) * 125);
  }

  html body .navbar-floating.footer-fixed .app-content .content-area-wrapper,
  html body .navbar-floating.footer-fixed .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 125 - calc(calc(2rem * 2) + 4.45rem + 3.35rem + 1.3rem + 0rem));
  }

  .user-messages {
    height: 100%;
  }

  body .chat-full-screen .chat-app-window {
    height: 100vh;
  }

  .chat-full-screen .chat-app-window {
    height: 100vh;
  }
}
.btn{
  text-transform: uppercase !important;
}
.action-icon-grid {
  width: 20px;
  cursor: pointer;
  margin: 0 10px 0 0;
}
</style>
