import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import axios from 'axios'

export default {
  namespaced: true,
  actions: {
    getStoreProfile(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/shops/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchByPhoneNumber(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/get_user/by_phone?phone=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchByEmail(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/get_user/by_email?email=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createUser(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/create_user`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createUserAddress(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/create_user_address`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateStoreProfile(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/shops/${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storeCancelOrder(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/dispatcher/cancel`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storeAcceptOrder(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/dispatcher/accept`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getNotifications() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/getNotification`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopPricingInfo(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/pricing/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    agentOrderDetail(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/agents/data/order/statistics?order_id=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAgentsStats() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/agents/data/statistics`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getStoreOrders(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/total/storeorder${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getStoreTypeList() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/storetypelist`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getShopUnits() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/units`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopAcceptedOrders() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/dispatcher/orders?type=ACCEPTED`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopOrders() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/dispatcher/orders?type=ORDERED`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storeReview() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/store/reviews`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createCategory(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/category`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createAddOns(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/addons`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createManualOrder(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/manual/create_order`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getShopCategory(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/category/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getShopAddOns(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/addons/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createPromocode(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/promocode`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCategory(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/category/${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAddOns(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/addons/${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePromocode(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/promocode/${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCategoryStatus(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/category/${payload.query}/updateStatus?status=${payload.status}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getStoreCountryCities(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/countrycities/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPromocodeInfo(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/promocode/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePromocode(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/promocode/${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addShopAgent(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/agent`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateShopAgent(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/agent/${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAgentStatus(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/agent/${payload.query}/updateStatus?status=${payload.status}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateItemsStatus(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/items/${payload.query}/updateStatus?status=${payload.status}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getShopAgentById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/agent/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addShopItems(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/items`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopCategoryList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/categorylist/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getShopItems(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/items/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopAddOnsList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/addonslist/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateBankDetails(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/editbankdetails`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateShopItems(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/items/${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopRequestHistory(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/requesthistory/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    depositAgentCOD(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/order/deposit/cod`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storeRequestHistoryList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/history/list/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    paymentConfig() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/payment/config`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    bankTemplates() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/bankdetails/template`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getShopPrice() {
      if (window.SuperApp.cache['/shop/get-store-price']) {
        return new Promise(resolve => {
          resolve(window.SuperApp.cache['/shop/get-store-price'])
        })
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/get-store-price`)
          .then(response => {
            window.SuperApp.cache['/shop/get-store-price'] = response
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    dispatcherInfo(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/dispatcher/orders?type=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePaymentConfig(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/payment/config`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storeAddCart(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/store/addcart`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveDeliverySettings(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/pricings`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storeRemoveCart(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/store/removecart`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopAssignAgentToOrder(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/agent/assign`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storeCartList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/store/cartlist${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getOrderDetailsById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/requesthistory/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    findAgentForOrder(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/agents/find/agents?order_id=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteStoreReview(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${jwtDefaultConfig.apiEndpoint}/${window.SuperApp.getters.isStore() ? 'shop' : 'provider'}/reviews/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createOverview(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/${window.SuperApp.getters.isStore() ? 'shop' : 'provider'}/overviews`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getOvewViewById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/${window.SuperApp.getters.isStore() ? 'shop' : 'provider'}/overviews/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editOverview(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/${window.SuperApp.getters.isStore() ? 'shop' : 'provider'}/overviews/${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editStorePhoto(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/${window.SuperApp.getters.isStore() ? 'shop' : 'provider'}/photos/${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addStorePhoto(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/${window.SuperApp.getters.isStore() ? 'shop' : 'provider'}/photos`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storePhotos(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/${window.SuperApp.getters.isStore() ? 'shop' : 'provider'}/photos/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPhotoDetail(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/${window.SuperApp.getters.isStore() ? 'shop' : 'provider'}/photos/details/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteStorePhoto(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${jwtDefaultConfig.apiEndpoint}/${window.SuperApp.getters.isStore() ? 'shop' : 'provider'}/photos/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopAgentList() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/agents/list/SHOP`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getLocationInfo(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/provider/getlocation${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopTransaction(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/transactions${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopNotificationCount() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/getNotification`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopNotifications(ctx, payload) {
      if (!payload) {
        // eslint-disable-next-line no-param-reassign
        payload = ''
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/notification${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    revertReview(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/${window.SuperApp.getters.isStore() ? 'shop' : 'provider'}/reviews`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    bookedTimeSlots(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/timeslots${payload}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    agentsPendingCODOrders(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/agents/data/orders${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopReview(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/store/reviews${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    executeGetApi(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultStoreUser() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/store/get_default_user`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopAdminServices() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/adminservices`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopListDocument(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/listdocuments`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteShopDocument(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${jwtDefaultConfig.apiEndpoint}/shop/shopdocument/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadShopDocument(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/documents`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeShopStatus(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/onlinestatus/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    reScheduleRequest(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/store/order/reschedule`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getChatNotifications() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/chats`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storeAutoComplete(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/user-search?stext=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopUploadItems(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/items/upload`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopTimeslotDetails() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/shopstiming`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopItems() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/shopitems`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateVariation(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/variations/list/${payload.query}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getVariationsList() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/variations/list?page=all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createVaraition(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/variations/list`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getVariationById(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/variations/list/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteVariation(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${jwtDefaultConfig.apiEndpoint}/shop/variations/list/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    courierCompany(ctx, payload = '') {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/courier/companies${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveCourierCompany(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/courier/companies`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveShopStaticPage(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/static/page`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getShopStaticPage(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/static/page${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    generateQr(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/generate/qr`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    printShopPdf(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/print/pdf?id=${payload}`, {
            responseType: 'blob',
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopChangePassword(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/password`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopPromocodes(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/promocodelist${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shipRocketPickup(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/shiprocket/pickups?${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopRocketManifest(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/shiprocket/manifest?${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopRocketLabel(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/shiprocket/label?${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopRocketInvoice(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/shiprocket/invoice?${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    shopRocketTracking(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/shiprocket/tracking_awb?${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getStoreTiming(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.apiEndpoint}/shop/agent/get/timing/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveStoreTiming(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${jwtDefaultConfig.apiEndpoint}/shop/agent/save/timing/${payload.id}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
