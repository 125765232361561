export default [
  {
    path: '/shop/login',
    name: 'apps-shop-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      sharedType: 'shop',
      type: 'shop',
    },
  },
  {
    path: '/shop/registration',
    name: 'apps-shop-registration',
    component: () => import('@/views/auth/Registration.vue'),
    meta: {
      layout: 'full',
      sharedType: 'shop',
      type: 'shop',
    },
  },
  {
    path: '/shop/forget-password',
    name: 'apps-shop-forget-password',
    component: () => import('@/views/auth/ForgetPassword.vue'),
    meta: {
      layout: 'full',
      sharedType: 'shop',
    },
  },
  {
    path: '/shop/change-password',
    name: 'shop-change-password',
    component: () => import('@/views/store/ChangePassword.vue'),
    meta: {
      pageTitle: 'Change Password',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/dashboard',
    name: 'shop-dashboard',
    component: () => import('@/views/store/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/analytics',
    name: 'shop-analytics',
    component: () => import('@/views/store/Analytics.vue'),
    meta: {
      pageTitle: 'Analytics',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/dispatcher',
    name: 'shop-dispatcher',
    component: () => import('@/views/store/Dispatcher.vue'),
    meta: {
      pageTitle: 'Dispatcher',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/my-documents',
    name: 'shop-documents',
    component: () => import('@/views/store/MyDocuments.vue'),
    meta: {
      pageTitle: 'My Documents',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/bank-details',
    name: 'shop-bank-details',
    component: () => import('@/views/store/BankDetails.vue'),
    meta: {
      pageTitle: 'Bank Details',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/profile',
    name: 'shop-profile',
    component: () => import('@/views/store/Profile.vue'),
    meta: {
      pageTitle: 'Profile',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/promocodes',
    name: 'shop-promocodes',
    component: () => import('@/views/store/Promocodes.vue'),
    meta: {
      pageTitle: 'Promocodes',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/category',
    name: 'shop-category',
    component: () => import('@/views/store/Category.vue'),
    meta: {
      pageTitle: 'Category',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/variations',
    name: 'shop-variations',
    component: () => import('@/views/store/Variations.vue'),
    meta: {
      pageTitle: 'Variations',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/agents',
    name: 'shop-agents',
    component: () => import('@/views/store/Agents.vue'),
    meta: {
      pageTitle: 'Agents',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/agents-dashboard',
    name: 'shop-agents-dashboard',
    component: () => import('@/views/store/AgentDashboard.vue'),
    meta: {
      pageTitle: 'Agent Dashboard',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/delivery-settings',
    name: 'shop-delivery-settings',
    component: () => import('@/views/store/DeliverySettings.vue'),
    meta: {
      pageTitle: 'Delivery Settings',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/notifications',
    name: 'shop-notifications',
    component: () => import('@/views/store/Notifications.vue'),
    meta: {
      pageTitle: 'Shop Notifications',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/reviews',
    name: 'shop-reviews',
    component: () => import('@/views/store/Reviews.vue'),
    meta: {
      pageTitle: 'Shop Reviews',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/messaging',
    name: 'shop-messaging',
    component: () => import('@/views/store/Messages.vue'),
    meta: {
      pageTitle: 'Shop Messages',
      showBreadCrumb: false,
      canActivate: true,
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/booking',
    name: 'shop-bookings',
    component: () => import('@/views/store/Bookings.vue'),
    meta: {
      pageTitle: 'Shop Booking',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/addons',
    name: 'shop-addons',
    component: () => import('@/views/store/AddOns.vue'),
    meta: {
      pageTitle: 'Shop Add Ons',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/order/find-agent/:orderId',
    name: 'shop-find-agent',
    component: () => import('@/views/store/FindAgents.vue'),
    meta: {
      pageTitle: 'Order Find Agent',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/user-reviews',
    name: 'shop-user-reviews',
    component: () => import('@/views/store/StoreReviews.vue'),
    meta: {
      pageTitle: 'Store Reviews',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/overviews',
    name: 'shop-overviews',
    component: () => import('@/views/store/Overviews.vue'),
    meta: {
      pageTitle: 'Store Overviews',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/photos',
    name: 'shop-photos',
    component: () => import('@/views/store/Photos.vue'),
    meta: {
      pageTitle: 'Store Photos',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/order/agent/view/:orderId',
    name: 'shop-agent-view',
    component: () => import('@/views/users/StoreOrder.vue'),
    meta: {
      pageTitle: 'Order Agent View',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/items',
    name: 'shop-items',
    component: () => import('@/views/store/Items.vue'),
    meta: {
      pageTitle: 'Items',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/wallet',
    name: 'shop-wallet',
    component: () => import('@/views/store/Wallets.vue'),
    meta: {
      pageTitle: 'Wallets',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/order-history',
    name: 'order-history',
    component: () => import('@/views/store/OrderHistory.vue'),
    meta: {
      pageTitle: 'Order History',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/order-statement',
    name: 'order-statement',
    component: () => import('@/views/store/OrderStatement.vue'),
    meta: {
      pageTitle: 'Order Statement',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/shop-statement',
    name: 'shop-statement',
    component: () => import('@/views/store/ShopStatement.vue'),
    meta: {
      pageTitle: 'Shop Statement',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/hrm-departments',
    name: 'shop-hrm-departments',
    component: () => import('@/views/shared/hrm/Department.vue'),
    meta: {
      pageTitle: 'HRM Departments',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/hrm-designation',
    name: 'shop-hrm-designation',
    component: () => import('@/views/shared/hrm/Designation.vue'),
    meta: {
      pageTitle: 'HRM Designation',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/hrm-attendance',
    name: 'shop-hrm-attendance',
    component: () => import('@/views/shared/hrm/Attendance.vue'),
    meta: {
      pageTitle: 'HRM Attendance',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/hrm-holidays',
    name: 'shop-hrm-holidays',
    component: () => import('@/views/shared/hrm/Holidays.vue'),
    meta: {
      pageTitle: 'HRM Holidays',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/hrm-shift',
    name: 'shop-hrm-shift',
    component: () => import('@/views/shared/hrm/OfficeShift.vue'),
    meta: {
      pageTitle: 'HRM Shift',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/hrm-employees',
    name: 'shop-hrm-employees',
    component: () => import('@/views/shared/hrm/employees'),
    redirect: "/shop/hrm-employees/list",
    meta: {
      pageTitle: 'HRM Employees',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
    children: [
      {
        name: "shop-employees_list",
        path: "list",
        component: () => import('@/views/shared/hrm/employees/index_employee'),
        meta: {
          pageTitle: 'HRM Employees',
          showBreadCrumb: false,
          canActivate: true,
          contentClass: 'ecommerce-application',
          type: 'shop',
        },
      },
      {
        name: "shop-store_employee",
        path: "store",
        component: () => import('@/views/shared/hrm/employees/employee_create'),
        meta: {
          pageTitle: 'HRM Employees',
          showBreadCrumb: false,
          canActivate: true,
          contentClass: 'ecommerce-application',
          type: 'shop',
        },
      },
      {
        name: "shop-edit_employee",
        path: "edit/:id",
        component: () => import('@/views/shared/hrm/employees/employee_edit'),
        meta: {
          pageTitle: 'HRM Employees',
          showBreadCrumb: false,
          canActivate: true,
          contentClass: 'ecommerce-application',
          type: 'shop',
        },
      },
      {
        name: "shop-detail_employee",
        path: "detail/:id",
        component: () => import('@/views/shared/hrm/employees/employee_details'),
        meta: {
          pageTitle: 'HRM Employees',
          showBreadCrumb: false,
          canActivate: true,
          contentClass: 'ecommerce-application',
          type: 'shop',
        },
      },
    ]
  },
  {
    path: '/shop/hrm-leaves',
    name: 'shop-hrm-leaves',
    component: () => import('@/views/shared/hrm/leaves'),
    redirect: "/shop/hrm-leaves/list",
    meta: {
      pageTitle: 'HRM Leaves',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
    children: [
      {
        name: "shop-leave_list",
        path: "list",
        component: () => import('@/views/shared/hrm/leaves/leave_list'),
        meta: {
          pageTitle: 'Leaves',
          showBreadCrumb: false,
          canActivate: true,
          contentClass: 'ecommerce-application',
          type: 'shop',
        },
      },
    ]
  },
  {
    path: '/shop/hrm-leave-types',
    name: 'shop-hrm-leave-types',
    component: () => import('@/views/shared/hrm/leaves'),
    redirect: "/shop/hrm-leave-types/type",
    meta: {
      pageTitle: 'HRM Leave Types',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
    children: [
      {
        name: "shop-leave_type",
        path: "type",
        component: () => import('@/views/shared/hrm/leaves/leave_type'),
        meta: {
          pageTitle: 'Leave Types',
          showBreadCrumb: false,
          canActivate: true,
          contentClass: 'ecommerce-application',
          type: 'shop',
        },
      },
    ]
  },
  {
    path: '/shop/payment-config',
    name: 'payment-config',
    component: () => import('@/views/store/PaymentConfig.vue'),
    meta: {
      pageTitle: 'Payment Config',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/policies',
    name: 'shop-policies',
    component: () => import('@/views/store/Policies.vue'),
    meta: {
      pageTitle: 'Shop Policies',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
]
