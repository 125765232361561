import { isUserLoggedIn } from '@/auth/utils'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { hideLoader, showDangerNotification, showLoader } from '@/@core/comp-functions/ui/app'
import admin from './routes/admin'
import provider from './routes/provider'
import store from './routes/store'
import users from './routes/users'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {
    console.log('Suspected Duplication Route')
  })
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '',
      name: 'landing-page',
      component: () => import('@/views/Home.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/shop',
      name: 'landing-shop-page',
      component: () => import('@/views/Shop.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/provider',
      name: 'landing-provider-page',
      component: () => import('@/views/Provider.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/',
      redirect: { name: 'user-landingpage' },
    },
    ...users,
    ...provider,
    ...store,
    ...admin,
    {
      path: '/terms-and-condition',
      name: 'terms-and-condition',
      component: () => import('@/views/TermsAndCondition.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: () => import('@/views/PrivacyPolicy.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/about-us',
      name: 'about-us',
      component: () => import('@/views/Aboutus.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/contact-us',
      name: 'contact-us',
      component: () => import('@/views/Contactus.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import('@/views/FAQ.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/knowledge-base',
      name: 'knowledge-base',
      component: () => import('@/views/KnowledgeBase.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/knowledge-base/category/:baseName/:baseId',
      name: 'blog-details-category',
      component: () => import('@/views/KnowledgeBaseBlogs.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/knowledge-base/details/:baseName/:categoryId/:knowledgeBaseId',
      name: 'knowledge-base-details-category',
      component: () => import('@/views/KnowledgeBaseDetails.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/blog',
      name: 'blog',
      component: () => import('@/views/blog/BlogList.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/blog/:blogName/:blogId',
      name: 'blog-details',
      component: () => import('@/views/blog/BlogDetail.vue'),
      meta: {
        layout: 'full',
        type: 'user',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  hideLoader()
})

router.beforeEach((to, from, next) => {
  const { canActivate, type } = to.meta
  showLoader()
  if (canActivate) {
    const isLoggedIn = isUserLoggedIn()
    if (!isLoggedIn) {
      if (type) {
        next({ path: `/${type}/login`, query: { returnUrl: to.path } })
      } else if (!isLoggedIn) {
        next({ path: '/user/login', query: { returnUrl: to.path } })
      }
    }
    if (isLoggedIn && to.name !== 'user-profile' && window.SuperApp.getters.isUser() && !window.SuperApp.validations.isUserDetailValid()) {
      next({ path: '/user/profile' })
      hideLoader()
      if (window.socketApp) {
        showDangerNotification(window.socketApp, 'Please fill the required details!')
      }
      return
    }
    if (isLoggedIn && to.name !== 'shop-profile' && window.SuperApp.getters.isStore() && !window.SuperApp.validations.isShopDetailValid()) {
      next({ path: '/shop/profile' })
      hideLoader()
      if (window.socketApp) {
        showDangerNotification(window.socketApp, 'Please fill the required details to go online')
      }
      return
    }
    if (isLoggedIn && to.name !== 'provider-profile' && window.SuperApp.getters.isProvider() && !window.SuperApp.validations.isProviderDetailValid()) {
      next({ path: '/provider/profile' })
      hideLoader()
      if (window.socketApp) {
        showDangerNotification(window.socketApp, 'Please fill the required details to go online')
      }
      return
    }
  }else if(to.name == 'user-dashboard' || to.name == 'apps-store-details'){
    const isLoggedIn = isUserLoggedIn()
    if (isLoggedIn && to.name !== 'user-profile' && window.SuperApp.getters.isUser() && !window.SuperApp.validations.isUserDetailValid()) {
      next({ path: '/user/profile' })
      hideLoader()
      if (window.socketApp) {
        showDangerNotification(window.socketApp, 'Please fill the required details!')
      }
      return
    }
  }
  Array.from(document.querySelectorAll('.mz-zoom-window'))
    .filter(x => x.remove)
    .map(x => x.remove())
  next()
})

export default router
