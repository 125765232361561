<script>
/* eslint-disable global-require */
import { showDangerNotification } from '@/@core/comp-functions/ui/app'

export default {
  imageHelper(url) {
    return window.SuperApp.helper.image(url)
  },
  toFixed(value, points) {
    if (!value) return value
    return parseFloat(parseFloat(value).toFixed(points))
  },
  downloadFile(that, url) {
    fetch(url)
      .then(res => res.blob())
      .then(file => {
        const tempUrl = URL.createObjectURL(file)
        const aTag = document.createElement('a')
        aTag.href = tempUrl
        // eslint-disable-next-line no-useless-escape
        aTag.download = url.replace(/^.*[\\\/]/, '')
        document.body.appendChild(aTag)
        aTag.click()
        URL.revokeObjectURL(tempUrl)
        aTag.remove()
      })
      .catch(() => {
        showDangerNotification(that, 'Failed to download file!')
      })
  },
  imageError($event) {
    if ($event.target.getAttribute && $event.target.getAttribute('data-hide') && $event.target.getAttribute('data-hide') === 'true') {
      if ($event.target.parentElement.nodeName === 'FIGURE') {
        $event.target.parentElement.classList.add('d-none')
      } else {
        $event.target.classList.add('d-none')
      }
    }
    $event.target.setAttribute('data-original-src', $event.target.src)
    // eslint-disable-next-line no-param-reassign
    $event.target.src = window.SuperApp.constants.noImageAvailable
  },
  replaceUnderscore(textContent) {
    return window.SuperApp.actions.replaceUnderscore(textContent)
  },
  getIcons(type = '') {
    if (type === 'delete') return require('@/assets/images/app-icons/delete_grid.png')
    if (type === 'edit') return require('@/assets/images/app-icons/edit_grid.png')
    if (type === 'status') return require('@/assets/images/app-icons/status_grid.png')
    if (type === 'update') return require('@/assets/images/app-icons/update_grid.png')
    if (type === 'chat') return require('@/assets/images/app-icons/Chat_New.png')
    if (type === 'view') return require('@/assets/images/app-icons/view_grid.png')
    if (type === 'viewitem') return require('@/assets/images/app-icons/view-48.png')
    if (type === 'payment') return require('@/assets/images/app-icons/accounts.png')
    if (type === 'dispute') return require('@/assets/images/app-icons/dispute_icon.jpeg')
    if (type === 'tracking') return require('@/assets/images/app-icons/track.png')
    if (type === 'reply') return require('@/assets/images/app-icons/send.png')
    if (type === 'bestseller') return require('@/assets/images/app-icons/bestseller.png')
    if (type === 'recommended') return require('@/assets/images/app-icons/recommended.png')
    if (type === 'new-product') return require('@/assets/images/app-icons/new-product.png')
    if (type === 'non-veg') return require('@/assets/images/app-icons/non-vegetarian-food-symbol.png')
    if (type === 'veg') return require('@/assets/images/app-icons/vegetarian-food-symbol.png')
    if (type === 'stock-out') return require('@/assets/images/app-icons/out-of-stock.png')
    if (type === 'cash') return require('@/assets/images/payment/Cash.png')
    if (type === 'card') return require('@/assets/images/app-icons/CARD.png')
    if (type === 'wallet') return require('@/assets/images/app-icons/wallet.png')
    if (type === 'card-on-delivery') return require('@/assets/images/app-icons/CARD_ON_DELIVERY.png')
    if (type === 'contact-less') return require('@/assets/images/app-icons/contact-less.svg')
    if (type === 'alert.png') return require('@/assets/images/app-icons/alerts/alert.png')
    if (type === 'closed.png') return require('@/assets/images/app-icons/alerts/closed.png')
    if (type === 'confirm.png') return require('@/assets/images/app-icons/alerts/confirm.png')
    if (type === 'notification.png') return require('@/assets/images/app-icons/alerts/notification.png')
    if (type === 'schedule.png') return require('@/assets/images/app-icons/alerts/schedule.png')
    if (type === 'download') return require('@/assets/images/app-icons/icons8-download-48.png')
    if (type === 'copy') return require('@/assets/images/app-icons/icons8-copy-48.png')
    if (type === 'save') return require('@/assets/images/app-icons/save-48.png')
    return type
  },
  swalIcon: icon => {
    const baseUrl = window.socketApp.$helpers.getIcons(icon)
    return `<img src="${baseUrl}" style="width: 70px;">`
  },
}
</script>
