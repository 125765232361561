/* eslint-disable eol-last */
import Vue from 'vue'
import { ToastPlugin, TooltipPlugin, BootstrapVueIcons, BIcon, BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import HighchartsVue from 'highcharts-vue'
import VueCompositionAPI from '@vue/composition-api'
import * as VueGoogleMaps from 'vue2-google-maps'
import * as XLSX from 'xlsx'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import axios from 'axios'
import i18n from '@/libs/i18n/index'
import VueZoomer from 'vue-zoomer'
import router from './router'
import store from './store'
import App from './App.vue'
import Helper from './views/Helper.vue'
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import './registerServiceWorker'
import './helper'
import './country'
import './city'
import jwtDefaultConfig from './@core/auth/jwt/jwtDefaultConfig'
// import 'vue-croppa/dist/vue-croppa.css'

// window.SuperApp.helper.appendJS('https://apis.google.com/js/api:client.js')
// Composition API
Vue.use(BootstrapVueIcons)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import * as rules from "vee-validate/dist/rules";

localize({
  en: {
    messages: {
      required: 'This field is required',
      required_if: 'This field is required',
      regex: 'This field must be a valid',
      mimes: `This field must have a valid file type.`,
      size: (_, { size }) => `This field size must be less than ${size}.`,
      min: 'This field must have no less than {length} characters',
      max: (_, { length }) => `This field must have no more than ${length} characters`
    }
  },
});
// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

// Register it globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.use(VueCompositionAPI)
Vue.component('BIcon', BIcon)
Vue.use(HighchartsVue)
Vue.use(CKEditor)
Vue.use(VueZoomer)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(TooltipPlugin)
// import core styles
require('@core/scss/core.scss')
// import assets styles
require('@/assets/scss/style.scss')

// import responsive styles
require('@/assets/scss/responsive.scss')

// import sitka fonts
// require('@core/assets/fonts/feather/playfair.css')
// require('./sentry')

pdfMake.vfs = pdfFonts.pdfMake.vfs
Vue.config.productionTip = false
window.XLSX = XLSX
window.pdfMake = pdfMake

axios.interceptors.request.use(request => {
  const token = localStorage.getItem(window.SuperApp.getters.getPrefixStorage('TokenKeyName'))

  if (window.SuperApp.getters.userInfo() && token && !request.headers.Authorization) {
    // eslint-disable-next-line no-param-reassign
    request.headers.Authorization = `${jwtDefaultConfig.tokenType} ${token}`
  }

  return request
})

axios.interceptors.response.use(
  response => response,
  error => {
    const { response } = error

    if (response && response.status === 401) {
      if (jwtDefaultConfig.reloadOn401) {
        localStorage.removeItem(window.SuperApp.getters.getPrefixStorage('TokenKeyName'))
        localStorage.removeItem(window.SuperApp.getters.getPrefixStorage('RefreshTokenKeyName'))
        window.SuperApp.getters.removeUserInfo()
        window.SuperApp.getters.removeUserAccess()
        window.location.reload()
      }
    }
    return Promise.reject(error)
  },
)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBWUH5PTHDGKyMibIlljIUgkSUYxBpFs24',
    libraries: 'places',
  },
})
const helpersPlugin = {
  install() {
    Vue.helpers = Helper
    Vue.prototype.$helpers = Helper
    Vue.prototype.$superApp = window.SuperApp
  },
}
Vue.use(helpersPlugin)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
localStorage.removeItem('userSelectedAddress')
localStorage.removeItem('userSelectedAddressObj')
