import moment from "moment"
/**
 *
 * @param {*} value
 * @returns {Boolean}
 */
export function isEmpty(value) {
  return (
    value === undefined ||
    value === 'undefined' ||
    value === null ||
    value === 'null' ||
    value === '' ||
    value === false ||
    value === 0 ||
    value === '0' ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'array' && value.length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}

/**
 *
 * @param {*} string
 * @returns
 */
export function generateKey(string) {
  return Number(new Date()) + Math.floor(Math.random() * 100000);
}


/**
 *
 * @param {*} value
 * @param {*} index
 * @param {*} self
 * @returns
 */
export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

export function onlyUniqueByKey(value, index, self, key) {
  return self.indexOf(value[key]) === index
}

export function objToQueryParams(obj) {
  if(isEmpty(obj)) return null;
  const arr = []
  Object.keys(obj).forEach(k => {
    const item = `${k}=${obj[k]}`
    arr.push(item)
  })
  return "?"+arr.join('&')
}

/**
 *
 * @param {*} date
 * @param {*} outputFormat
 * @returns
 */
export function dateTimeFormat(date, outputFormat) {
  return moment(date).format(outputFormat)
}


export function decodeLaravelValidationErrors(Errs) {
  let errors = []

  if (typeof Errs === "string") {
    return Errs;
  }

  if (typeof Errs !== 'object') return false

  if (Object.keys(Errs).length === 0) return false

  for (let key in Errs) {
    if (Errs[key].length > 0) {
      for (let ek in Errs[key]) {
        errors.push(Errs[key][ek])
      }
    }
  }

  if (errors.length === 0) return false

  return errors
}

/**
 *
 * @param {*} value
 * @returns
 */
export function isNumeric(value = null) {
  if (isEmpty(value)) {
    return false
  }
  return !isNaN(parseInt(value))
}



export function dateDiff(_date1, _date2){
  const date1 = new Date(_date1);
  const date2 = new Date(_date2);
    
  const Difference_In_Time = date2.getTime() - date1.getTime();
    
  return Difference_In_Time / (1000 * 3600 * 24);
}